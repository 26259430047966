.create-listing-input-small {
  width: 175px;
  height: 48px;
}

.per-rate-unit {
  width: 100px;
  margin: 0 0 0 12px;
}

.breakline-margin {
  margin: 40px 580px 0 0;
}
