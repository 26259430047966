.input-field-common {
  border-radius: 3px;
  border: solid 1px #333f48;
  margin-top: 12px;
  padding: 14px 16px 14px 16px;
  color: #333f48b3;
}

.creating-listing-input-field {
  width: 100%;
  height: 48px;
}

.creating-listing-input-big-area {
  width: 100%;
  height: 241px;
}

.creating-listing-input-area {
  width: 100%;
  height: 132px;
}

.details-step-note {
  width: 569px;
  height: 17px;
  font-size: 14px;
  text-align: center;
  color: #333f48;
}
