.create-listing-from-date .SingleDatePickerInput {
  width: 293px;
  height: 48px;
  border-radius: 3px;
  border: solid 1px #333f48;
}

.create-listing-from-date {
  margin: 12px 0 0 0;
}

.create-listing-from-date .DateInput {
  padding: 0 10px 1px 10px !important;
  width: 70% !important;
}

.create-listing-from-date .SingleDatePickerInput_calendarIcon_svg {
  margin: 0 !important;
  width: 26px;
  height: 26px;
  fill: #333f48;
}

.create-listing-from-date .SingleDatePicker_picker__openDown {
  margin: 12px 0 0 0;
}

.SingleDatePicker_picker {
  box-shadow: 0;
  border: solid 1px #333f48;
}

@media (min-width: 1127px) {
  .SingleDatePicker_picker {
    width: 443px;
  }
}

.DateInput_input,
.DateInput_input_1 {
  cursor: pointer;
}
