.radio-container {
  display: block;
  position: relative;
  /* padding-left: 40px; */
  padding-top: 0;
  padding-left: 30px;
  margin-left: 4px;
  margin-bottom: 22px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 32px;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.2px;
}

/* Hide the browser's default checkbox */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #333f48;
  border-radius: 100%;
  border-radius: 50%;
}

/* When the radio button is checked, add a white background */
.radio-container input:checked ~ .radio-checkmark {
  background-color: #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
  top: 3px;
  left: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #70787e;
}
