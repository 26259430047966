.admin-section-subtitle {
  width: 134px;
  margin-right: 14px;
}

.admin-content-font {
  width: 100%;
  color: #333f48b3;
}

.location-section-address {
  padding: 16px 0 12px 16px;
}

.admin-modify-button {
  width: 100%;
  text-align: right;
  color: #0290be;
}
