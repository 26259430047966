.payout-option-row {
  margin-top: 12px;
}

.payout-option-text {
  width: 151px;
  color: #0290be;
}

.add-another-listing-button {
  width: 295px;
  height: 48px;
  border-radius: 3px;
  border: 0;
  background-color: #fde300;
  text-align: center;
  margin-top: 40px;
}

.view-my-listing-button {
  width: 295px;
  height: 48px;
  border-radius: 3px;
  border: solid 1px #333f48;
  background-color: #ffffff;
  font-size: 20px;
  text-align: center;
  margin-top: 40px;
}
