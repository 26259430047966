.nav-item-active {
  height: 20px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #144392;
  padding-bottom: 10px;
  border-bottom: 4px solid #144392;
  z-index: 1;
}
