.listing-detail-page-subtitle {
  width: 100%;
  height: 20px;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin-bottom: 24px;
}

.listing-details-more-option-boundary {
  height: 108px;
}
