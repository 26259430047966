.focused-progress-title {
  width: 121px;
  height: 17px;
  font-size: 14px;
  font-weight: 600;
}

.unfocused-progress-title {
  width: 121px;
  height: 17px;
  font-size: 14px;
  color: #333f48b3;
}

.create-listing-page-title {
  width: 100%;
  height: 20px;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin-bottom: 32px;
}

.find-my-addr {
  width: 123px;
  font-size: 16px;
  color: #333f48;
  margin-bottom: 12px;
}

.blue-button {
  width: 120px;
  height: 48px;
  border: 0;
  border-radius: 3px;
  background-color: #01bac7;
  color: #333f48;
  text-align: center;
  margin-right: 180px;
}

.addr-autocomplete-input-group {
  margin-bottom: 78px;
  display: flex;
}

.creating-listing-input-normal {
  width: 293px;
  height: 48px;
  border-radius: 3px;
  border: solid 1px #333f48;
  margin-top: 12px;
  padding: 14px 16px 14px 16px;
  color: #333f48b3;
}

.form-group {
  margin-bottom: 0;
}

.manual-input-row {
  margin-bottom: 32px;
}

.create-listing-selector .Select-placeholder,
.create-listing-selector .Select-value-label {
  margin-top: 6px;
  padding-top: 8px;
  width: 148px;
  color: #333f48b3 !important;
}

.create-listing-button {
  width: 212px;
  height: 48px;
  border-radius: 3px;
  border: 0;
  background-color: #fde300;
  font-size: 20px;
  text-align: center;
}

hr.create-listing-location-break-line {
  overflow: visible; /* For IE */
  padding: 0;
  border: none;
  color: #70787e;
  text-align: center;
  border-top: 1px solid #d6d9da;
}

hr.create-listing-location-break-line:after {
  content: 'Or, enter manually';
  display: inline-block;
  position: relative;
  top: -0.7em;
  font-size: 1.5em;
  font-size: 16px;
  padding: 0 30px;
  background: white;
  color: #70787e;
}

/* style for google autocomplete */
.pac-container:after {
  content: none !important;
}

.pac-container {
  font-family: 'Rubik', sans-serif;
}

.pac-item {
  height: 48px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.pac-item-query {
  font-size: 16px !important;
  margin-right: 12px;
}

.pac-item-selected {
  background-color: #eaebec;
}

.pac-item-selected .pac-icon {
  color: rgba(51, 63, 72, 0.7);
}
