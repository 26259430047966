.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 27px;
  border: 0;
  border-radius: 50px;
}

.switch input {
  display: none;
}

.listing-detail-more-option-toggle {
  position: absolute;
  border-radius: 50px;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #adb2b6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* input:checked + .tick {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
} */

.listing-detail-more-option-toggle:before {
  position: absolute;
  border-radius: 50px;
  content: '';
  height: 21px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .listing-detail-more-option-toggle {
  background-color: #144392;
}

input:focus + .listing-detail-more-option-toggler {
  box-shadow: 0 0 1px #144392;
}

input:checked + .listing-detail-more-option-toggle:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}
