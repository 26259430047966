.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 2;
}

.modal_input {
  padding: 11px 50px 11px 16px;
  background-color: #ffffff;
  border: 1px solid #333f48;
  border-radius: 3px;
  width: 100%;
  height: 48px;
  color: rgba(51, 63, 72, 0.699999988079071);
}

.button {
  background-color: #fde300;
  border: 0;
}

.secondary-button {
  border: 1px solid #333f48;
  background-color: #ffffff;
}

.button,
.secondary-button {
  display: inline-block;
  border-radius: 3px;
  width: 100%;
  height: 48px;
  font-size: 20px;
  text-align: center;
}

.forgot_password {
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
}

.close {
  position: absolute;
  right: 20px;
  top: 25px;
  width: 31px;
  height: 31px;
  opacity: 1;
}

.close:hover {
  opacity: 1;
}

.close:before,
.close:after {
  position: absolute;
  left: 0px;
  content: ' ';
  height: 45px;
  width: 2px;
  background-color: #333;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.validation_error {
  border: 1px solid #ff0467;
}
